// This is a list of all the event names that we are tracking in Segment

// EVENTS
export const CLIENT_CANCELLED_JOB = "Client Cancelled Job";
export const CLIENT_RESCHEDULED_JOB = "Client Rescheduled Job";
export const CLIENT_SHARED_JOB_COMPLETED_FEEDBACK =
  "Client Shared Job Completed Feedback";
export const ELEMENT_INTERACTED_WITH = "Element Interacted With";
export const JOB_ADDRESS_ENTERED = "Job Address Entered";
export const JOB_BOOKED = "Job Booked";
export const USER_LOGGED_IN = "User Logged In";

export const TASKER_ACCOUNT_CREATED = "Tasker Account Created";
export const USER_ACCOUNT_CREATED = "User Account Created";
export const IKEA_DESIGN_CODE_SEARCHED = "IKEA Design Code Searched";
export const ADDING_MORE_ITEMS_ON_DESIGN_CODE = "Adding More Items on Design Code Flow";
export const IKEA_DESIGN_CODE_CHECKOUT_COMPLETED = "IKEA Design Code Checkout";
export const IKEA_ARTICLES_SEARCHED = "IKEA Articles Searched";
export const IKEA_ARTICLES_ADDED = "IKEA Articles Added";
export const DOWNLOAD_APP_MODAL = "Download App Modal";

export const RESCUE_TASKER_ENGAGED = "Rescue Taskers Engaged";
export const FIND_ANOTHER_TASKER_ENGAGED = "Find Another Tasker Engaged"

// PAGES
export const USER_RESET_PASSWORD = "User Reset Password";
export const DASHBOARD = "Dashboard";
export const MARKETING_GROUP_PAGE_VIEWED = "Marketing Group Page";
export const PAGE_JOB_ADDRESS = "Job Address";
export const PAGE_DASHBOARD_TASK_DETAILS = "Dashboard Task Details";
export const PAGE_REFERRAL = "Referral";
export const PAGE_SERVICES_INDEX = "Services Index";
export const PAGE_TASKER_PROFILE = "Tasker Profile";
export const PAGE_WAIVER = "Waiver";
export const PAGE_TASKER_DOCUMENT_VERIFICATION = "Tasker Document Verification";
export const PAGE_BECOME_TASKER = "Become a Tasker";
export const PAGE_TR_FOR_GOOD = "Taskrabbit For Good";
export const JOB_FEEDBACK = "Job Feedback";
export const JOB_FEEDBACK_REQUEST = "Job Feedback Request";
export const JOB_FEEDBACK_TIP_REQUEST = "Job Feedback Tip Request";
export const PASSWORD_RESET = "Password Reset";
export const SIGN_IN = "Sign In";
export const SIGN_UP = "Sign Up";
export const IKEA_HOME = "IKEA Home";
export const IKEA_SEARCH = "IKEA Job Item Search";
export const IKEA_HIRE = "IKEA Job Details";
export const IKEA_QUOTE_SEARCH = "IKEA Quote Item Search";
export const IKEA_QUOTE_CONFIRMATION = "IKEA Quote Confirmation";
export const IKEA_QUOTE_DETAILS = "IKEA Quote Details";
export const IKEA_EDIT_JOB = "IKEA Simplified Product Edit-Booked Jobs";

// Account Pages
export const ACCOUNT_PROFILE = "Account Profile"
export const ACCOUNT_SECURITY = "Account Security"
export const ACCOUNT_BILLING = "Account Billing"
export const ACCOUNT_BILLING_EDIT = "Account Billing Edit"
export const ACCOUNT_BUSINESS_INFO = "Account Business Information"
export const ACCOUNT_CANCEL_TASK = "Account Cancel Task"
export const ACCOUNT_DELETE = "Account Delete"
export const ACCOUNT_NOTIFICATIONS = "Account Notifications"
export const ACCOUNT_PASSWORD = "Account Password"
export const ACCOUNT_BALANCE = "Account Balance"
export const ACCOUNT_TRANSACTIONS = "Account Transactions"
export const ACCOUNT_TASKER_INFO = "Account Tasker Information"
export const ACCOUNT_TAX_ID = "Account Tax ID"
export const PROFILE_SETTINGS = "Profile Settings"

// JOB ADDRESS PAGE VARIANT
export const PAGE_VARIANT = "booking_details";

// PAGE VIEWS
export const RECOMMENDATION_PAGE_VIEWED = "Recommendations";
export const FIRST_RECOMMENDATION_VIEWED = "First Recommendation Viewed";

// FLOWS
export const RESCUE_SELECT_ANOTHER_TASKER = "Rescue - Select Another Tasker";
export const REBOOK_TASKER = "Rebook";

// CLICKS
export const RECOMMENDATION_CATEGORY_PICKED = "Recommendation Category Picked";
export const USER_LOGIN_BUTTON_CLICKED = "User Log In Button Clicked";
export const CLIENT_CLICKED_CONTACT_TASKRABBIT_BUTTON =
  "Client Clicked Contact TaskRabbit Button";
export const CLIENT_CLICKED_SHOW_HIDE_RECEIPT_BUTTON =
  "Client Clicked Show / Hide Receipt Button";
export const CLIENT_CLICKED_CONTACT_IKEA_BUTTON =
  "Client Clicked Contact Ikea Button";
export const CLIENT_CLICKED_DOWNLOAD_APP_BUTTON =
  "Client Clicked Download App Button";
export const CLIENT_UPDATED_PAYMENT_METHOD = "Client Updated Payment Method";
export const CLIENT_CLICKED_CHAT_BUTTON = "Client Clicked Chat Button";
